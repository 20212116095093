import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import NewProject from './NewProject';
import Header from './Header';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <div className="bg-gray-100 text-gray-700">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/new-project" element={<NewProject />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
