import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <header className="bg-blue-600 text-white fixed w-full top-0 z-50">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <div>
            <Link to="/"><h1 className="text-3xl font-bold">Shuntaro Kishida</h1></Link>
            <p className="text-lg">Aspiring full stack developer with a focus on backend development</p>
          </div>
          <nav>
            <ul className="hidden md:flex space-x-4">
              <li><Link smooth to="/#about" className="hover:underline">About Me</Link></li>
              <li><Link smooth to="/#projects" className="hover:underline">Projects</Link></li>
              <li><Link smooth to="/#skills" className="hover:underline">Skills</Link></li>
              <li><Link smooth to="/#contact" className="hover:underline">Contact</Link></li>
            </ul>
            <div className="md:hidden">
              <button onClick={toggleMobileMenu} className="text-6xl">&#9776;</button>
            </div>
          </nav>
        </div>
      </header>
      {isMobileMenuOpen && (
        <div id="mobileMenu" className="fixed inset-0 bg-blue-600 text-white z-50 flex flex-col items-center justify-center">
          <button onClick={toggleMobileMenu} className="text-4xl font-bold mb-8">&times;</button>
          <ul className="space-y-4 text-center">
            <li><Link smooth to="/#about" className="block text-2xl hover:underline" onClick={toggleMobileMenu}>About Me</Link></li>
            <li><Link smooth to="/#projects" className="block text-2xl hover:underline" onClick={toggleMobileMenu}>Projects</Link></li>
            <li><Link smooth to="/#skills" className="block text-2xl hover:underline" onClick={toggleMobileMenu}>Skills</Link></li>
            <li><Link smooth to="/#contact" className="block text-2xl hover:underline" onClick={toggleMobileMenu}>Contact</Link></li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
