import React from 'react';
import './App.css'; // TailwindCSSのスタイルが適用されるようにインポート

function NewProject() {
  return (
    <main className="container mx-auto p-4 mt-24">
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">新プロジェクト詳細</h2>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center">
          <img src="./images/image01.png" alt="New Project Image 1" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:ml-4">
            <h1 className="text-xl font-bold mb-2">静的ページ</h1>
            <p className="mb-4">一般の方々が閲覧可能なページです。いわゆるホームページになります。教室の紹介をメインとした部分となります。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center md:flex-row-reverse">
          <img src="./images/image02.png" alt="New Project Image 2" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:mr-4">
            <h1 className="text-xl font-bold mb-2">会員ログイン機能</h1>
            <p className="mb-4">会員ログイン機能を搭載しております。「会員」とは、教室の関係者（管理者、生徒様など）を指します。ログインすることによって、会員しか閲覧することのできない情報を閲覧することができるようになります。会員は「管理者、教授、生徒」の3つの権限に分かれており、権限ごとに閲覧、操作が可能な項目が異なります。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center">
          <img src="./images/image03.png" alt="New Project Image 3" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:ml-4">
            <h1 className="text-xl font-bold mb-2">ユーザー管理機能</h1>
            <p className="mb-4">管理者権限の会員のみ閲覧、操作が可能な機能です。ユーザー（会員）の新規登録、詳細確認、情報更新、削除の操作が可能となっています。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center md:flex-row-reverse">
          <img src="./images/image04.png" alt="New Project Image 4" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:mr-4">
            <h1 className="text-xl font-bold mb-2">カリキュラム進捗確認機能</h1>
            <p className="mb-4">ユーザー（会員）詳細画面にてカリキュラムの進捗状況の確認が可能となります。当該の会員が現在どのカリキュラムに取り組んでいるのかはもちろん、過去のカリキュラムの開始年月日や修了年月日まで確認が可能です。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center">
          <img src="./images/new-portfolio-image.png" alt="New Project Image 5" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:ml-4">
            <h1 className="text-xl font-bold mb-2">画面タイトル5</h1>
            <p className="mb-4">この画面の説明文5。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center md:flex-row-reverse">
          <img src="./images/new-portfolio-image.png" alt="New Project Image 6" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:mr-4">
            <h1 className="text-xl font-bold mb-2">画面タイトル6</h1>
            <p className="mb-4">この画面の説明文6。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center">
          <img src="./images/new-portfolio-image.png" alt="New Project Image 7" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:ml-4">
            <h1 className="text-xl font-bold mb-2">画面タイトル7</h1>
            <p className="mb-4">この画面の説明文7。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center md:flex-row-reverse">
          <img src="./images/new-portfolio-image.png" alt="New Project Image 8" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:mr-4">
            <h1 className="text-xl font-bold mb-2">画面タイトル8</h1>
            <p className="mb-4">この画面の説明文8。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center">
          <img src="./images/new-portfolio-image.png" alt="New Project Image 9" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:ml-4">
            <h1 className="text-xl font-bold mb-2">画面タイトル9</h1>
            <p className="mb-4">この画面の説明文9。</p>
          </div>
        </div>
        <div className="bg-white p-4 rounded shadow mb-8 flex flex-col md:flex-row items-center md:flex-row-reverse">
          <img src="./images/new-portfolio-image.png" alt="New Project Image 10" className="w-full md:w-1/2 rounded mb-4 md:mb-0" />
          <div className="text-gray-700 md:mr-4">
            <h1 className="text-xl font-bold mb-2">画面タイトル10</h1>
            <p className="mb-4">この画面の説明文10。</p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default NewProject;
