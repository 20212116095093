import React from 'react';
import './App.css';
import { HashLink as Link } from 'react-router-hash-link';

function Home() {
  return (
    <main className="container mx-auto p-4 mt-24">
      <section id="about" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">About Me</h2>
        <div className="bg-white p-4 rounded shadow flex items-center">
          <img src="./images/me.jpeg" alt="Profile Picture" className="w-48 h-48 rounded-full mr-4" />
          <div>
            <h1 className="text-3xl font-bold">岸田 俊太郎</h1>
            <h2 className="text-xl">Shuntaro Kishida</h2>
            <p className="mt-4">
              1984年 東京都新宿区生まれ。学習院高等科卒業。コールセンターや事務仕事をしていたが、手に職をつけようと思い立ちITエンジニアを目指す。Tech Campというプログラミングスクールを卒業し、現在はSES事業の会社で正社員として働く。将来、フルスタックエンジニアになるため、様々な案件先の現場にて実務経験を積む日々！<br />
              手に職を持っている人間は強い。社会人になって、それだけは確信した。
            </p>
          </div>
        </div>
      </section>
      <section id="projects" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-white p-4 rounded shadow">
            <div className="mb-4">
              <img src="./images/portfolio-assett2.png" alt="Portfolio Image" className="w-full rounded" />
            </div>
            <div>
              <h1 className="text-xl font-bold mb-2">江戸千家 渭白流 様<br />公式ホームページ</h1>
              <p className="mb-4">
                江戸千家 渭白流 様の茶道教室の公式ホームページを制作させていただきました。保守・運用も承っております。
              </p>
              <p className="text-sm text-gray-500 mb-4">使用している技術: HTML, CSS, JavaScript</p>
              <a href="http://edosenkewakabakai.com" className="text-blue-600 hover:underline">http://edosenkewakabakai.com</a>
            </div>
          </div>
          <div className="bg-white p-4 rounded shadow">
            <div className="my-6">
              <img src="./images/image00.png" alt="New Project Image" className="w-full rounded" />
            </div>
            <div>
              <h1 className="text-xl font-bold mb-2">江戸千家 渭白流 様<br />新バージョン（開発中）</h1>
              <p className="mb-4">
                江戸千家 渭白流 様の新しいバージョンの公式ホームページを開発中です。CRUDを取り入れた新機能や改善点を追加しています。
              </p>
              <p className="text-sm text-gray-500 mb-4">使用している技術: PHP, Laravel, Docker, MySQL</p>
              <Link to="/new-project" className="text-blue-600 hover:underline">詳細を見る</Link>
            </div>
          </div>
          {/* 他のプロジェクトもここに追加可能 */}
        </div>
      </section>
      <section id="skills" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Skills</h2>
        <div className="bg-white p-4 rounded shadow">
          <ul className="list-disc pl-5">
            <li>HTML, CSS, JavaScript</li>
            <li>PHP, Ruby</li>
            <li>Laravel, Ruby on Rails</li>
            <li>React</li>
            <li>MySQL, PostgreSQL</li>
            <li>Docker, Git</li>
            <li>（学習中）: JAVA, Spring Framework</li>
          </ul>
        </div>
      </section>
      <section id="contact" className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Contact</h2>
        <div className="bg-white p-4 rounded shadow">
          <p>メールアドレス: juntailangantian997@gmail.com</p>
        </div>
      </section>
    </main>
  );
}

export default Home;
